import { config } from "./config";

export const root = "/";
export const login = "/sign-in";
export const signup = "/";
export const userRoles = "/user/accounts";
export const signUpPta = "/sign-up/pta";
export const business = "/sign-up/business";
export const signUpBusiness = "/sign-up/business/form";
export const home = "/";
export const verify = "/verify-email";
export const verified = "/email-verified";
export const lpMember = "/sign-up/member";
export const signUpMember = "/sign-up/member/form";
export const rolesHub = "/user/accounts";

export const ptaBase = "/pta";
export const businessBase = "/business";
export const cmBase = "/cm";

// PTA
export const ptaDashboard = `${ptaBase}/dashboard`;
export const ptaMembers = `${ptaBase}/members`;
export const ptaCalendar = `${ptaBase}/calendar`;
export const fundraiserList = `${ptaBase}/fundraiser/list`;
export const fundraiserCreation = `${ptaBase}/fundraiser/create`;
export const PTASettings = `${ptaBase}/settings`;
export const PTAProfile = `${ptaBase}/profile`;
export const PTARefer = `${ptaBase}/refer`;
export const ptaMessages = `${ptaBase}/messages`;

// Business
export const businessDashboard = `${businessBase}/dashboard`;
export const businessCoupons = `${businessBase}/coupons/list`;
export const businessCouponsCreate = `${businessBase}/coupons/create`;
export const businessCouponsEdit = `${businessBase}/coupons/`;
export const businessCalendar = `${businessBase}/calendar`;
export const businessProfile = `${businessBase}/profile`;
export const businessSelector = `${businessBase}/hub`;
export const businessMessages = `${businessBase}/messages`;
export const businessUserProfile = `${businessBase}/user/profile`;

// CM
export const cmDashboard = `${cmBase}/dashboard`;
export const cmCoupons = `${cmBase}/coupons`;
export const cmCalendar = `${cmBase}/calendar`;
export const cmRefer = `${cmBase}/refer/business`;
export const cmUserProfile = `${cmBase}/user/profile`;
export const cmMessages = `${cmBase}/messages`;
