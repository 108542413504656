import Link from "next/link";
import DarkModeSwitcher from "./DarkModeSwitcher";
import Image from "next/image";
import { home } from "@/config/routes";
import dynamic from "next/dynamic";
import { DropdownOption } from "./DropdownOptions";

const DynamicDropdownUser = dynamic(() => import("./DropdownUser"));

const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
  hideSidebar: boolean;
  hideUserIcon?: boolean;
  sidebarOnlyDesktop?: boolean;
  dropdownOptions?: Array<DropdownOption> | undefined;
}) => {
  return (
    <header className="sticky top-0 z-999 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
      <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
        <div
          className={`items-center gap-2 sm:gap-4 lg:hidden  ${props.hideSidebar ? "hidden" : "flex"} ${props.sidebarOnlyDesktop === true ? "hidden lg:flex" : ""}`}
        >
          {/* <!-- Hamburger Toggle BTN --> */}
          {!props.hideSidebar && (
            <button
              aria-controls="sidebar"
              onClick={(e) => {
                e.stopPropagation();
                props.setSidebarOpen(!props.sidebarOpen);
              }}
              className="z-99999 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark lg:hidden"
            >
              <span className="relative block h-5.5 w-5.5 cursor-pointer">
                <span className="du-block absolute right-0 h-full w-full">
                  <span
                    className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-[0] duration-200 ease-in-out dark:bg-white ${
                      !props.sidebarOpen && "!w-full delay-300"
                    }`}
                  ></span>
                  <span
                    className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-150 duration-200 ease-in-out dark:bg-white ${
                      !props.sidebarOpen && "delay-400 !w-full"
                    }`}
                  ></span>
                  <span
                    className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-200 duration-200 ease-in-out dark:bg-white ${
                      !props.sidebarOpen && "!w-full delay-500"
                    }`}
                  ></span>
                </span>
                <span className="absolute right-0 h-full w-full rotate-45">
                  <span
                    className={`absolute left-2.5 top-0 block h-full w-0.5 rounded-sm bg-black delay-300 duration-200 ease-in-out dark:bg-white ${
                      !props.sidebarOpen && "!h-0 !delay-[0]"
                    }`}
                  ></span>
                  <span
                    className={`delay-400 absolute left-0 top-2.5 block h-0.5 w-full rounded-sm bg-black duration-200 ease-in-out dark:bg-white ${
                      !props.sidebarOpen && "!h-0 !delay-200"
                    }`}
                  ></span>
                </span>
              </span>
            </button>
          )}
          {/* <!-- Hamburger Toggle BTN --> */}
        </div>
        <Link href={home}>
          <Image
            className="block dark:hidden ms-3"
            src={"/images/logo/logo-dark.svg"}
            width={125}
            height={50}
            alt="logo-dark"
          />
          <Image
            className="hidden dark:block"
            src={"/images/logo/logo.svg"}
            width={125}
            height={50}
            alt="logo"
          />
        </Link>
        <div
          className={`flex self-end items-center w-full  justify-end gap-3 2xsm:gap-7 ${props.hideSidebar ? "ml-auto" : ""}`}
        >
          <ul className="flex items-center gap-2 2xsm:gap-4">
            {/* <!-- Dark Mode Toggler --> */}
            <DarkModeSwitcher />
            {/* <!-- Dark Mode Toggler --> */}
          </ul>

          {/* <!-- User Area --> */}
          {!(props.hideSidebar && props.hideUserIcon) && (
            <DynamicDropdownUser dropdownOptions={props.dropdownOptions} />
          )}
          {/* <!-- User Area --> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
