export const config = {
  env: process.env.NEXT_PUBLIC_ENV,
  url: process.env.NEXT_PUBLIC_URL,
  api_url: process.env.NEXT_PUBLIC_API_URL,
  backend_url: process.env.NEXT_PUBLIC_BACKEND_URL,
  google_auth_id: process.env.NEXT_PUBLIC_GOOGLE_ID,
  google_auth_secret: process.env.NEXT_PUBLIC_GOOGLE_SECRET,
  google_maps_key: process.env.NEXT_PUBLIC_MAPS_KEY,
  default_latitude: process.env.NEXT_PUBLIC_DEFAULT_LATITUDE,
  default_longitude: process.env.NEXT_PUBLIC_DEFAULT_LONGITUDE,
  sales_website: process.env.NEXT_PUBLIC_SALES_WEBSITE,
};
