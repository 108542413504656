"use client";
import Header from "@/components/Header";
import { SnackbarProvider, closeSnackbar } from "notistack";
import React, { useState } from "react";
import { SidebarOption } from "../Sidebar/Sidebars";
import { DropdownOption } from "../Header/DropdownOptions";
import Sidebar from "../Sidebar";

const Layout = ({
  showSidebar,
  children,
  sidebarOptions,
  showUserIcon,
  onlyDesktop,
  dropdownOptions,
}: {
  showSidebar: boolean;
  children: React.ReactNode;
  sidebarOptions?: Array<SidebarOption>;
  showUserIcon?: boolean;
  onlyDesktop?: boolean;
  dropdownOptions?: Array<DropdownOption>;
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <SnackbarProvider
      maxSnack={6}
      data-test="alert"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      classes={{ containerRoot: "z-999999" }}
      action={(snackbarId) => (
        <button className="p-2" onClick={() => closeSnackbar(snackbarId)}>
          x
        </button>
      )}
    >
      <div className="dark:bg-boxdark-2 dark:text-bodydark">
        <div className="flex h-screen overflow-hidden">
          {/* <!-- ===== Sidebar Start ===== --> */}
          {showSidebar && (
            <Sidebar
              sidebarOptions={sidebarOptions || []}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              onlyDesktop={onlyDesktop}
            />
          )}
          {/* <!-- ===== Sidebar End ===== --> */}

          {/* <!-- ===== Content Area Start ===== --> */}
          <div className="relative flex flex-1 flex-col overflow-y-hidden overflow-x-hidden">
            {/* <!-- ===== Header Start ===== --> */}
            <Header
              sidebarOpen={showSidebar ? sidebarOpen : undefined}
              setSidebarOpen={showSidebar ? setSidebarOpen : () => false}
              hideSidebar={!showSidebar}
              hideUserIcon={!showUserIcon}
              sidebarOnlyDesktop={onlyDesktop}
              dropdownOptions={dropdownOptions}
            />
            {/* <!-- ===== Header End ===== --> */}

            {/* <!-- ===== Main Content Start ===== --> */}
            <main className="flex-grow overflow-y-auto h-full">
              <div className="mx-auto max-w-screen p-2 sm:p-4 md:p-6 2xl:p-10">
                {children}
              </div>
            </main>
            {/* <!-- ===== Main Content End ===== --> */}
          </div>
          {/* <!-- ===== Content Area End ===== --> */}
        </div>
      </div>
    </SnackbarProvider>
  );
};

export default Layout;
